<template>
    <div>
        <psi-app-loader v-if="loading"></psi-app-loader>
        <v-card v-else max-width="1200" class="mx-auto" outlined>
            <div v-if="type == 'Address'">
                <rental-reference-pdf
                    v-if="pdf"
                    :reference="reference"
                ></rental-reference-pdf>
                <rental-reference-form
                    v-else
                    :url="url"
                    :reference="reference"
                    @submit="saveReference($event)"
                ></rental-reference-form>
            </div>
            <div v-if="type == 'Employer'">
                <employment-reference-pdf
                    v-if="pdf"
                    :reference="reference"
                ></employment-reference-pdf>
                <employment-reference-form
                    v-else
                    :url="url"
                    :reference="reference"
                    @submit="saveReference($event)"
                ></employment-reference-form>
            </div>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "rental-reference",
    components: {
        RentalReferencePdf: () => import("@components/references/components/RentalReferencePdf"),
        RentalReferenceForm: () => import("@components/references/components/RentalReferenceForm"),
        EmploymentReferenceForm: () => import("@components/references/components/EmploymentReferenceForm"),
        EmploymentReferencePdf: () => import("@components/references/components/EmploymentReferencePdf"),
    },
    props: {
        url: {
            type: String,
            required: true,
            default: "",
        },
        pdf: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    computed: {
        ...mapGetters("Reference", ["loading", "reference", "type"]),
    },
    mounted() {
        this.getReference(this.url);
    },
    methods: {
        ...mapActions("Reference", ["getReference", "saveReference"]),
    }
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("psi-app-loader")
        : _c(
            "v-card",
            {
              staticClass: "mx-auto",
              attrs: { "max-width": "1200", outlined: "" }
            },
            [
              _vm.type == "Address"
                ? _c(
                    "div",
                    [
                      _vm.pdf
                        ? _c("rental-reference-pdf", {
                            attrs: { reference: _vm.reference }
                          })
                        : _c("rental-reference-form", {
                            attrs: { url: _vm.url, reference: _vm.reference },
                            on: {
                              submit: function($event) {
                                return _vm.saveReference($event)
                              }
                            }
                          })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "Employer"
                ? _c(
                    "div",
                    [
                      _vm.pdf
                        ? _c("employment-reference-pdf", {
                            attrs: { reference: _vm.reference }
                          })
                        : _c("employment-reference-form", {
                            attrs: { url: _vm.url, reference: _vm.reference },
                            on: {
                              submit: function($event) {
                                return _vm.saveReference($event)
                              }
                            }
                          })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }